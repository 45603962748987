import styled, { css, keyframes } from 'styled-components';
import { TextField } from '@material-ui/core';

const fadeIn = keyframes`
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

const detectBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes('Chrome')) {
    return 'chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'firefox';
  } else if (userAgent.includes('Safari')) {
    return 'safari';
  } else {
    return 'other'; // Para outros navegadores
  }
};

const getScrollbarStyles = (browser: string) => {
  if (browser !== 'firefox') {
    return css`
      /* Estilizando o Scrollbar para Chrome/Safari */
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px; /* Para barras de rolagem horizontais */
      }

      &::-webkit-scrollbar-thumb {
        cursor: pointer;
        background-color: ${props =>
          props.theme.secundaria}; /* Cor do "thumb" */
        border-radius: 10px;
        border: 3px solid transparent;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Cor do "track" */
      }

      /* Remove as setas do scrollbar em WebKit */
      &::-webkit-scrollbar-button {
        display: none; /* Remove as setas do scrollbar */
      }
    `;
  } else {
    return css`
      /* Estilizando o Scrollbar para Firefox */
      scrollbar-width: thin; /* Largura do scrollbar: "auto" ou "thin" */
      scrollbar-color: ${props => props.theme.secundaria} #f0f0f0; /* Cor do thumb e do track */
    `;
  }
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  animation: ${fadeIn} 2s;

  height: 100vh;
  overflow: hidden;

  .MuiTextField-root {
    /* contorno do input */
    & .MuiOutlinedInput-root {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: ${props => props.theme.secundaria} !important;
        }
      }
    }
    /* Contorno do input no hover */
    &:hover {
      & .MuiOutlinedInput-root {
        fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-color: ${props => props.theme.secundariaShade} !important;
          }
        }
      }
    }
    /* contorno quando foca no input */
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: ${props => props.theme.secundariaShade} !important;
      }
    }
    /* cor da fonte do input */
    & .MuiOutlinedInput-input {
      color: ${props => props.theme.texto} !important;
      /* letra maiúscula no input */
      text-transform: uppercase;
    }
    /* label quando preenchido */
    & label.MuiFormLabel-filled {
      color: ${props => props.theme.secundaria} !important;
    }
    /* label em cima do input */
    & label.Mui-focused {
      color: ${props => props.theme.secundariaShade} !important;
    }
    /* seria o placeholder */
    > label {
      color: ${props => props.theme.textoClaro};
    }
  }
`;

export const ContainerInputs = styled.div`
  display: flex;
  align-items: center;

  gap: 5px;

  button {
    height: 56px;
    width: 110px;
    margin-top: 5px;

    padding: 8px;
    background: ${props => props.theme.secundaria};
    color: #fff;

    border: none;
    border-radius: 8px;

    font-weight: bold;
    font-size: 16px;

    &:hover {
      background: ${props => props.theme.secundariaShade};
    }
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;
  gap: 10px;

  flex: 1;
  overflow-y: auto;

  height: 80%;
  overflow-y: auto;
  ${getScrollbarStyles(detectBrowser())};
`;

export const CardConvenio = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;

  margin-right: 10px;

  cursor: pointer;

  padding: 15px;

  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.2);
  &:hover {
    box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 0.2);
  }

  background-color: #f5f5f5;
  border-radius: 8px;

  div {
    display: flex;
    align-items: center;
    flex-direction: row;

    gap: 10px;
  }

  img {
    display: flex;

    height: 40px;
    width: 60px;

    object-fit: contain;
  }

  label {
    cursor: pointer;

    transition: background-color 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }
  }

  svg {
    color: ${props => props.theme.secundaria};
    &:hover {
      color: ${props => props.theme.secundariaShade};
    }

    height: 22px;
    width: 22px;
  }
`;

// export const InputConvenio = styled.div`
//   flex: 1;
//   height: 58px;
//   display: flex;
//   background-color: #fff;
//   border: 1px solid ${props => props.theme.secundaria};
//   border-radius: 8px;

//   input {
//     flex: 1;
//     height: 53px;
//     margin: 0 5px;
//     padding: 0 5px;
//     border: none;
//     color: ${props => props.theme.texto};
//     font-size: 16px;

//     ::placeholder {
//       color: ${props => props.theme.textoClaro};
//     }
//   }
// `;

export const InputConvenio = styled(TextField)`
  flex: 1;
`;

export const GroupBox = styled.fieldset`
  border: 1px solid ${props => props.theme.secundaria} !important;
  border-radius: 10px;
  padding: 0 20px 15px !important;

  height: 100%;
`;

export const GroupBoxLegenda = styled.legend`
  font-size: 20px !important;
  /* font-weight: bold !important; */
  text-align: left !important;
  color: ${props => props.theme.secundaria};
  width: auto;
  padding: 0px 5px;
  border-bottom: none;
`;
