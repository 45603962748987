import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactElement,
} from 'react';
import api from '../../services/api';
import DayPicker, { DayModifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { useConfigs } from '../../context/ConfigsContext';
import { useAuth } from '../../context/AuthContext';
import { Link, useHistory } from 'react-router-dom';

import Convenio from '../Convenio';
import Categoria from '../Categoria';

import {
  FiLogOut,
  FiPlusCircle,
  FiChevronLeft,
  FiChevronRight,
  FiUser,
  FiUserPlus,
  FiClipboard,
  FiBookmark,
} from 'react-icons/fi';

import defaultAvatar from '../../assets/default-avatar.png';
import logoImg from '../../assets/mundo-aba-logo.png';
import logoPas from '../../assets/logoPas.png';
import logoUnimed from '../../assets/logo unimed.png';

import {
  Container,
  AgendaContainer,
  AgendaColumn,
  Cabecalho,
  ConteudoCabecalho,
  Perfil,
  ConteudoPerfil,
  BotaoNovo,
  Calendario,
  VisoesLinha,
  LinhaCelular,
  ControleCelularContainer,
  ControlDiv,
  BotoesControle,
  ListaEspera,
  PacienteEsperando,
  MenuIcon,
  RelogioIcon,
  LinhaAgenda,
  HoraAgenda,
  EspacoAgenda,
  EspacoAgendaConteudo,
  CabecalhoColunaLateral,
  CalendarioColumn,
  ListaEsperaColumn,
  Carrossel,
  CarrouselBotao,
  CarrosselContainer,
  CarrosselItem,
  SetaEsquerdaIcon,
  SetaDireitaIcon,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  ContainerCadastros,
  BotaoMenu,
} from './styles';

interface PacienteEsperando {
  id: number;
  name: string;
  category: string;
  color: string;
  plano: string;
}

interface Profissionais {
  id: number;
  name: string;
}

interface Horarios {
  id: number;
  hora: string;
}

interface HorariosAgendados {
  id: number;
  hora: string;
  paciente: string;
  data: Date;
  categoria: string;
  categoriaCor: string;
  profissionalNome: string;
  plano: string;
}

const profissionais: Profissionais[] = [
  { id: 45, name: 'Sérgio Tiago Carlos' },
  { id: 32, name: 'Arthur Manoel Novaes' },
  { id: 77, name: 'Jennifer Daniela Souza' },
  { id: 97, name: 'Benício Henry da Silva' },
  { id: 31, name: 'Isabela Laís Ayla' },
  { id: 62, name: 'Stella Bárbara Santos' },
  { id: 14, name: 'Nicole Eduarda Freitas' },
  { id: 2, name: 'Maria Alessandra Ferreira' },
  { id: 19, name: 'Francisco Eduardo Pires' },
  { id: 37, name: 'Clarice Larissa dos Santos' },
  { id: 44, name: 'Marcos Manoel Castro' },
];

//const waiting: PacienteEsperando[] = [];
const listaEspera: PacienteEsperando[] = [
  {
    id: 1,
    name: 'Caleb Marcos Brito',
    category: 'Psicólogo',
    color: '#00FA9A',
    plano: 'pas',
  },
  {
    id: 2,
    name: 'Amanda Sophia Farias',
    category: 'Fonoaudiólogo',
    color: '#9932CC',
    plano: 'pas',
  },
  {
    id: 3,
    name: 'Jorge Bernardo Leandro Lima',
    category: 'Clínico Geral',
    color: '#FF4500',
    plano: 'unimed',
  },
  {
    id: 4,
    name: 'Bianca Catarina Benedita Melo',
    category: 'Terapia Ocupacional',
    color: '#FFD700',
    plano: 'pas',
  },
];

const slots: Horarios[] = [
  {
    id: 1,
    hora: '07:00',
  },
  {
    id: 2,
    hora: '08:00',
  },
  {
    id: 3,
    hora: '09:00',
  },
  {
    id: 4,
    hora: '10:00',
  },
  {
    id: 5,
    hora: '11:00',
  },

  {
    id: 6,
    hora: '12:00',
  },
  {
    id: 7,
    hora: '13:00',
  },
  {
    id: 8,
    hora: '14:00',
  },
  {
    id: 9,
    hora: '15:00',
  },
  {
    id: 10,
    hora: '16:00',
  },
  {
    id: 11,
    hora: '17:00',
  },
  {
    id: 12,
    hora: '18:00',
  },
  {
    id: 13,
    hora: '19:00',
  },
];

const slotsAgendados: HorariosAgendados[] = [
  {
    id: 1,
    hora: '09:00',
    paciente: 'Hugo Kauê Monteiro',
    data: new Date('10/31/2024'),
    categoria: 'Psicólogo',
    categoriaCor: '#00FA9A',
    profissionalNome: 'Sérgio Tiago Carlos',
    plano: 'pas',
  },
  {
    id: 2,
    hora: '11:00',
    paciente: 'Enzo Gabriel Araújo',
    data: new Date('10/14/2024'),
    categoria: 'Psicólogo',
    categoriaCor: '#00FA9A',
    profissionalNome: 'Isabela Laís Ayla',
    plano: 'unimed',
  },
  {
    id: 3,
    hora: '09:00',
    paciente: 'Enzo Gabriel Araújo',
    data: new Date('10/14/2024'),
    categoria: 'Fonoaudiólogo',
    categoriaCor: '#9932CC',
    profissionalNome: 'Arthur Manoel Novaes',
    plano: 'unimed',
  },
  {
    id: 4,
    hora: '13:00',
    paciente: 'Enzo Gabriel Araújo',
    data: new Date('10/31/2024'),
    categoria: 'Terapia Ocupacional',
    categoriaCor: '#FFD700',
    profissionalNome: 'Sérgio Tiago Carlos',
    plano: 'pas',
  },
  {
    id: 5,
    hora: '08:00',
    paciente: 'Enzo Gabriel Araújo',
    data: new Date('10/31/2024'),
    categoria: 'Clínico Geral',
    categoriaCor: '#FF4500',
    profissionalNome: 'Stella Bárbara Santos',
    plano: 'pas',
  },
  {
    id: 6,
    hora: '07:00',
    paciente: 'Lucca Leandro Isaac',
    data: new Date('10/17/2024'),
    categoria: 'Psicólogo',
    categoriaCor: '#00FA9A',
    profissionalNome: 'Stella Bárbara Santos',
    plano: 'unimed',
  },
  {
    id: 7,
    hora: '09:00',
    paciente: 'Luna Giovana Araújo',
    data: new Date('10/31/2024'),
    categoria: 'Fonoaudiólogo',
    categoriaCor: '#9932CC',
    profissionalNome: 'Stella Bárbara Santos',
    plano: 'pas',
  },
  {
    id: 8,
    hora: '07:00',
    paciente: 'Luna Giovana Araújo',
    data: new Date('10/31/2024'),
    categoria: 'Fonoaudiólogo',
    categoriaCor: '#9932CC',
    profissionalNome: 'Stella Bárbara Santos',
    plano: 'unimed',
  },
  {
    id: 9,
    hora: '10:00',
    paciente: 'Luna Giovana Araújo',
    data: new Date('11/01/2024'),
    categoria: 'Fonoaudiólogo',
    categoriaCor: '#9932CC',
    profissionalNome: 'Sérgio Tiago Carlos',
    plano: 'unimed',
  },
];

const Agenda: React.FC = () => {
  const { lerConfig } = useConfigs();
  const { user, signOut } = useAuth();

  const history = useHistory();

  const diasDesabilitados = [{ daysOfWeek: [0, 6] }];

  const carrosselRef = useRef<HTMLDivElement>(null);
  const [profissionaisSelecionados, setProfissionaisSelecionados] = useState<
    number[]
  >([45]);
  const [datePickerExpandido, setDatePickerExpandido] =
    useState<boolean>(false);
  const [listaEsperaExpandida, setListaEsperaExpandida] =
    useState<boolean>(false);
  const [dataSelecionada, setDataSelecionada] = useState(new Date());
  const [logoDinamico, setLogoDinamico] = useState<string | undefined>(
    undefined,
  );
  const [isMobile, setIsMobile] = useState(false);
  const [menuSelecionado, setMenuSelecionado] = useState<string | undefined>(
    undefined,
  );

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    if (!carrosselRef.current) return;

    const { current: carrossel } = carrosselRef;

    carrossel.style.scrollBehavior = 'auto';

    const startX = e.pageX - carrossel.offsetLeft;
    const scrollLeft = carrossel.scrollLeft;

    const onMouseMove = (e: MouseEvent) => {
      const x = e.pageX - carrossel.offsetLeft;
      const scroll = x - startX;
      carrossel.scrollLeft = scrollLeft - scroll;
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      carrossel.style.scrollBehavior = 'smooth';
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }, []);

  const scrollLeft = () => {
    if (carrosselRef.current) {
      const { current: carrossel } = carrosselRef;

      const carrosselWidth = carrossel.clientWidth;

      const itemWidth = carrossel.firstElementChild?.clientWidth || 0;

      const itemsPerPage = Math.floor(carrosselWidth / itemWidth);

      carrossel.scrollLeft -= itemsPerPage * itemWidth;
    }
  };

  const scrollRight = () => {
    if (carrosselRef.current) {
      const { current: carrossel } = carrosselRef;

      const carrosselWidth = carrossel.clientWidth;

      const itemWidth = carrossel.firstElementChild?.clientWidth || 0;

      const itemsPerPage = Math.floor(carrosselWidth / itemWidth);

      carrossel.scrollLeft += itemsPerPage * itemWidth;
    }
  };

  const renderizaTelasMenu = useCallback((): ReactElement => {
    let telaCarregar: ReactElement = <></>;

    switch (menuSelecionado) {
      case 'CONVENIO':
        telaCarregar = <Convenio />;
        break;
      case 'CATEGORIA':
        telaCarregar = <Categoria />;
        break;
    }

    return telaCarregar;
  }, [menuSelecionado]);

  const toggleSelecionado = useCallback(
    (id: number) => {
      if (isMobile) {
        setProfissionaisSelecionados([id]);
      } else {
        if (profissionaisSelecionados.includes(id)) {
          // Se o profissional já está selecionado, remove da lista
          setProfissionaisSelecionados(
            profissionaisSelecionados.filter(
              profissionalId => profissionalId !== id,
            ),
          );
        } else {
          // Se não estiver selecionado, adicionar à lista
          setProfissionaisSelecionados([...profissionaisSelecionados, id]);
        }
      }

      if (carrosselRef.current) {
        carrosselRef.current.scrollLeft = 0;
      }
    },
    [isMobile, profissionaisSelecionados],
  );

  const toggleListaExpandida = useCallback(() => {
    setListaEsperaExpandida(!listaEsperaExpandida);

    if (isMobile) {
      setDatePickerExpandido(false);
    }
  }, [isMobile, listaEsperaExpandida]);

  const toggleDatePickerExpandido = useCallback(() => {
    setDatePickerExpandido(!datePickerExpandido);

    if (isMobile) {
      setListaEsperaExpandida(false);
    }
  }, [isMobile, datePickerExpandido]);

  const resumeNome = (nomeCompleto: String) => {
    const nomes = nomeCompleto.trim().split(' ');

    if (nomes.length > 1) {
      return `${nomes[0]} ${nomes[1]}`;
    }
  };

  const estaDesabilitado = (date: Date) => {
    const diaSemana = date.getDay();
    return diasDesabilitados.some(desabilitado =>
      desabilitado.daysOfWeek?.includes(diaSemana),
    );
  };

  const vaiParaDiaAnterior = () => {
    let diaAnterior = new Date(dataSelecionada);
    diaAnterior.setDate(dataSelecionada.getDate() - 1);

    while (estaDesabilitado(diaAnterior)) {
      diaAnterior.setDate(diaAnterior.getDate() - 1);
    }

    setDataSelecionada(diaAnterior);
  };

  const vaiParaProximoDia = () => {
    let proximoDia = new Date(dataSelecionada);
    proximoDia.setDate(dataSelecionada.getDate() + 1);

    while (estaDesabilitado(proximoDia)) {
      proximoDia.setDate(proximoDia.getDate() + 1);
    }

    setDataSelecionada(proximoDia);
  };

  const vaiParaHoje = () => {
    let hoje = new Date();

    while (estaDesabilitado(hoje)) {
      hoje.setDate(hoje.getDate() + 1);
    }

    setDataSelecionada(hoje);
  };

  const listaProfissionaisOrdenada = useCallback(() => {
    const profissionaisOrdenados = [...profissionais].sort((a, b) => {
      const isASelected = profissionaisSelecionados.includes(a.id);
      const isBSelected = profissionaisSelecionados.includes(b.id);

      if (isASelected && !isBSelected) return -1;
      if (!isASelected && isBSelected) return 1;

      return a.name.localeCompare(b.name);
    });

    return profissionaisOrdenados;
  }, [profissionaisSelecionados]);

  const listaProfissionaisSelecionados = useCallback(() => {
    return listaProfissionaisOrdenada().filter(profissional =>
      profissionaisSelecionados.includes(profissional.id),
    );
  }, [profissionaisSelecionados, listaProfissionaisOrdenada]);

  const handleDateChange = useCallback(
    (day: Date, modifiers: DayModifiers) => {
      if (modifiers.available && !modifiers.disabled) {
        setDataSelecionada(day);
      }

      if (isMobile) {
        setDatePickerExpandido(false);
      }

      setMenuSelecionado(undefined);
    },
    [isMobile],
  );

  const handleMenuClick = useCallback(
    (nomeMenu: string) => {
      if (menuSelecionado === nomeMenu) {
        setMenuSelecionado(undefined);
      } else {
        setMenuSelecionado(nomeMenu);
      }

      if (isMobile) {
        setDatePickerExpandido(false);
      }
    },
    [isMobile, menuSelecionado],
  );

  const abrirProfile = useCallback(() => {
    history.push('/profile');
  }, [history]);

  const agendadoProfissional = useCallback(
    (profissionalNome: string, hora: string) => {
      return slotsAgendados.find(agendamento => {
        return (
          agendamento.profissionalNome === profissionalNome &&
          agendamento.hora === hora &&
          agendamento.data.toLocaleDateString() ===
            dataSelecionada.toLocaleDateString()
        );
      });
    },
    [dataSelecionada],
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let configLogo: string | undefined = lerConfig('LOGO');
    if (configLogo && configLogo !== '') {
      api
        .get<string>('/empresas/configs-caminho-arquivo', {
          params: {
            arquivo: configLogo,
          },
        })
        .then(response => {
          setLogoDinamico(response.data);
        });
    } else {
      configLogo = undefined;
    }
  }, [lerConfig]);

  return (
    <Container>
      <Cabecalho>
        <ConteudoCabecalho>
          <Link to="/home">
            <div>
              <img src={logoDinamico ? logoDinamico : logoImg} alt="Logo" />
            </div>
          </Link>

          {user.empresa.utiliza_login === 'S' && (
            <Perfil>
              {/* <ul>
                <li>
                  <button type="button" onClick={abrirProfile}>
                    <FiUser />
                    <span>Perfil</span>
                  </button>
                </li>
                <li>
                  <button type="button" onClick={signOut}>
                    <FiPower />
                    <span>Sair</span>
                  </button>
                </li>
              </ul> */}
              <img
                src={!user.avatar_url ? defaultAvatar : user.avatar_url}
                alt={user.name}
              />
              <ConteudoPerfil>
                <div>
                  <button type="button" onClick={abrirProfile}>
                    <FiUser />
                    <span>Perfil</span>
                  </button>
                </div>
                <div>
                  <button type="button" onClick={signOut}>
                    <FiLogOut />
                    <span>Sair</span>
                  </button>
                </div>
              </ConteudoPerfil>
            </Perfil>
          )}
        </ConteudoCabecalho>
      </Cabecalho>

      <AgendaContainer>
        <CalendarioColumn expanded={datePickerExpandido} visible={true}>
          {datePickerExpandido ? (
            <>
              <CabecalhoColunaLateral>
                <MenuIcon
                  onClick={() => {
                    toggleDatePickerExpandido();
                  }}
                />
              </CabecalhoColunaLateral>

              <Calendario>
                <DayPicker
                  weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                  disabledDays={diasDesabilitados}
                  selectedDays={dataSelecionada}
                  month={dataSelecionada}
                  fixedWeeks
                  modifiers={{
                    available: { daysOfWeek: [1, 2, 3, 4, 5] },
                  }}
                  onDayClick={handleDateChange}
                  months={[
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro',
                  ]}
                />
              </Calendario>

              <BotaoMenu
                isActive={menuSelecionado === 'CONVENIO'}
                onClick={() => {
                  handleMenuClick('CONVENIO');
                }}
              >
                <FiBookmark /> Convenio
              </BotaoMenu>

              <BotaoMenu
                isActive={menuSelecionado === 'CATEGORIA'}
                onClick={() => {
                  handleMenuClick('CATEGORIA');
                }}
              >
                <FiClipboard /> Categoria
              </BotaoMenu>

              <BotaoMenu
                isActive={menuSelecionado === 'PACIENTE'}
                onClick={() => {
                  handleMenuClick('PACIENTE');
                }}
              >
                <FiUserPlus /> Paciente
              </BotaoMenu>
            </>
          ) : (
            <MenuIcon
              onClick={() => {
                toggleDatePickerExpandido();
              }}
            />
          )}
        </CalendarioColumn>

        <ListaEsperaColumn
          expanded={listaEsperaExpandida}
          visible={menuSelecionado ? false : true}
        >
          {listaEsperaExpandida ? (
            <>
              <CabecalhoColunaLateral>
                <RelogioIcon
                  onClick={() => {
                    toggleListaExpandida();
                  }}
                />
              </CabecalhoColunaLateral>

              <p>Lista de Espera</p>

              <ListaEspera>
                <BotaoNovo>
                  <button>
                    <div>
                      <FiPlusCircle />
                      <span>Adicionar Paciente</span>
                    </div>
                  </button>
                </BotaoNovo>

                {listaEspera.length > 0 ? (
                  <div>
                    {listaEspera.map(paciente => {
                      return (
                        <>
                          <PacienteEsperando
                            title={paciente.category}
                            data-tip={paciente.category}
                            key={paciente.id}
                            categoryColor={paciente.color}
                          >
                            {resumeNome(paciente.name)}

                            {paciente.plano === 'pas' ? (
                              <img
                                src={logoPas}
                                alt="Logo Padre Albino Saúde"
                              />
                            ) : (
                              <img src={logoUnimed} alt="Logo Unimed" />
                            )}
                          </PacienteEsperando>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <p>Nenhum paciente na lista de espera no momento</p>
                  </div>
                )}
              </ListaEspera>
            </>
          ) : (
            <RelogioIcon
              onClick={() => {
                toggleListaExpandida();
              }}
            />
          )}
        </ListaEsperaColumn>

        <AgendaColumn>
          {menuSelecionado ? (
            <ContainerCadastros>{renderizaTelasMenu()}</ContainerCadastros>
          ) : (
            <>
              <VisoesLinha active={false}>
                <CarrosselContainer>
                  <CarrouselBotao className="prev" onClick={scrollLeft}>
                    <SetaEsquerdaIcon />
                  </CarrouselBotao>

                  <Carrossel ref={carrosselRef} onMouseDown={handleMouseDown}>
                    {listaProfissionaisOrdenada().map(profissional => (
                      <CarrosselItem
                        key={profissional.id}
                        onClick={() => {
                          toggleSelecionado(profissional.id);
                        }}
                        selected={profissionaisSelecionados.includes(
                          profissional.id,
                        )}
                      >
                        <img src={defaultAvatar} alt="avatar-profissional" />
                        <p>{resumeNome(profissional.name)}</p>
                      </CarrosselItem>
                    ))}
                  </Carrossel>

                  <CarrouselBotao className="next" onClick={scrollRight}>
                    <SetaDireitaIcon />
                  </CarrouselBotao>
                </CarrosselContainer>

                <ControlDiv>
                  <BotoesControle>
                    <button
                      className="previewButton"
                      onClick={vaiParaDiaAnterior}
                    >
                      <FiChevronLeft />
                    </button>

                    <button className="todayButton" onClick={vaiParaHoje}>
                      Hoje
                    </button>

                    <button className="nextButton" onClick={vaiParaProximoDia}>
                      <FiChevronRight />
                    </button>
                  </BotoesControle>

                  <p>
                    {dataSelecionada.toLocaleDateString('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })}
                  </p>
                </ControlDiv>
              </VisoesLinha>

              <LinhaCelular>
                <p>
                  {dataSelecionada.toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </p>

                <ControleCelularContainer>
                  <SetaEsquerdaIcon onClick={vaiParaDiaAnterior} />
                  <button onClick={vaiParaHoje}>Voltar para hoje</button>
                  <SetaDireitaIcon onClick={vaiParaProximoDia} />
                </ControleCelularContainer>
              </LinhaCelular>

              <LinhaAgenda>
                {profissionaisSelecionados.length === 1 ? (
                  <>
                    {slots.map(horario => {
                      const horarioAgendado = agendadoProfissional(
                        listaProfissionaisSelecionados()[0].name,
                        horario.hora,
                      );

                      return (
                        <div>
                          <HoraAgenda>{horario.hora}</HoraAgenda>
                          <EspacoAgenda
                            key={horario.id}
                            agendado={!!horarioAgendado}
                            cor={horarioAgendado?.categoriaCor}
                          >
                            {horarioAgendado ? (
                              <EspacoAgendaConteudo>
                                <div>
                                  <p>{horarioAgendado.paciente}</p>
                                  <p>{horarioAgendado.categoria}</p>
                                </div>
                                {horarioAgendado.plano === 'pas' ? (
                                  <img
                                    src={logoPas}
                                    alt="Logo Padre Albino Saúde"
                                  />
                                ) : (
                                  <img src={logoUnimed} alt="Logo Unimed" />
                                )}
                              </EspacoAgendaConteudo>
                            ) : (
                              ''
                            )}
                          </EspacoAgenda>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <Table>
                    <thead>
                      <TableRow>
                        <TableHeader>Horários</TableHeader>
                        {listaProfissionaisSelecionados().map(profissional => (
                          <TableHeader key={profissional.id}>
                            {resumeNome(profissional.name)}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </thead>
                    <tbody>
                      {slots.map(horario => (
                        <TableRow key={horario.id}>
                          <TableCell style={{ width: '40px' }}>
                            {horario.hora}
                          </TableCell>
                          {listaProfissionaisSelecionados().map(
                            profissional => {
                              const Agendamento = agendadoProfissional(
                                profissional.name,
                                horario.hora,
                              );
                              return (
                                <TableCell
                                  key={profissional.id}
                                  style={{ minWidth: '325px' }}
                                >
                                  {Agendamento ? (
                                    <EspacoAgenda
                                      key={horario.id}
                                      agendado={!!Agendamento}
                                      cor={Agendamento?.categoriaCor}
                                    >
                                      <EspacoAgendaConteudo>
                                        <div>
                                          <p>{Agendamento.paciente}</p>
                                          <p>{Agendamento.categoria}</p>
                                        </div>
                                        {Agendamento.plano === 'pas' ? (
                                          <img
                                            src={logoPas}
                                            alt="Logo Padre Albino Saúde"
                                          />
                                        ) : (
                                          <img
                                            src={logoUnimed}
                                            alt="Logo Unimed"
                                          />
                                        )}
                                      </EspacoAgendaConteudo>
                                    </EspacoAgenda>
                                  ) : (
                                    ''
                                  )}
                                </TableCell>
                              );
                            },
                          )}
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                )}
              </LinhaAgenda>
            </>
          )}
        </AgendaColumn>
      </AgendaContainer>
    </Container>
  );
};

export default Agenda;
